
// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"

export const userSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token:null
  },
  reducers: {
    registerUser: (state, action) => {
      state.user = action.payload.user
      state.token = action.payload.token
    }
  }
})

export const { registerUser } = userSlice.actions

export default userSlice.reducer
